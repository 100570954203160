import { useEffect, useState } from "react";

import { Steps } from "./Steps";
import { SelectedType } from "../../types/schedule";
import { channelChecker, SuccessTextChecker } from "../../utils/checker";
import { SuccessCard } from "./SuccessCard";
import { sendGA4Events } from "../../lib/ga4";

const { sendItemClick } = sendGA4Events({ campaign: "bd-tlc-dmax-lp-202411" });

const GameCard = ({
  data,
  setLocked,
}: {
  data: SelectedType;
  setLocked: (value: boolean) => void;
}) => {
  const [step, setStep] = useState<number>(5);

  const [suggest, setSuggest] = useState<string>("");

  const [recommendations, setRecommendations] = useState<string[]>([]);

  const [filteredRecommendations, setFilteredRecommendations] = useState<
    string[]
  >([]);

  const [movieSelected, setMovieSelected] = useState<boolean>(false);

  const [successText, setSuccessText] = useState<string>("");

  const [finished, setFinished] = useState<boolean>(false);

  const [logo, setLogo] = useState<string>("");

  const checkLocalStore = () => {
    const successData = localStorage.getItem("successData");
    if (successData) {
      const parsedData = JSON.parse(successData);
      const playedTime = new Date(parsedData.playedTime);
      const start = new Date(data?.startTime);
      const end = new Date(data?.endTime);
      if (
        playedTime.getTime() >= start.getTime() &&
        playedTime.getTime() <= end.getTime() &&
        parsedData.isPlayed
      ) {
        setFinished(true);
      } else if (
        playedTime.getTime() >= start.getTime() &&
        playedTime.getTime() <= end.getTime() &&
        !parsedData.isPlayed
      ) {
        localStorage.removeItem("successData");
        setFinished(false);
      }
    }
  };

  useEffect(() => {
    checkLocalStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const logo = channelChecker(data?.channel);
    setLogo(logo);
  }, [data?.channel]);

  useEffect(() => {
    const loadRecommendations = async () => {
      const channel = data?.channel;
      const response = await fetch(
        `/assets/movies/${
          channel === "TLC"
            ? "tlc_programs_list.json"
            : "dmax_programs_list.json"
        }`,
      );
      const jsonData = await response.json();
      setRecommendations(jsonData);
    };

    loadRecommendations();
  }, [data.channel]);

  useEffect(() => {
    if (suggest.length > 0 && !movieSelected) {
      const filtered = recommendations.filter((rec) =>
        rec.toLocaleLowerCase("tr").startsWith(suggest.toLocaleLowerCase("tr")),
      );
      const limitedFiltered = filtered.slice(0, 10);

      setFilteredRecommendations(limitedFiltered);
    } else {
      setFilteredRecommendations([]);
    }
  }, [suggest, recommendations, movieSelected]);

  const clearFilterRecommendations = () => {
    setFilteredRecommendations([]);
  };

  const handleStep = () => {
    const suggestMovie = suggest.toLocaleLowerCase("tr");
    const dataMovie = data?.movie.toLocaleLowerCase("tr");
    const nowDate = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "Europe/Istanbul",
      }),
    );

    const saveSuccessData = (isPlayed: boolean) => {
      localStorage.setItem(
        "successData",
        JSON.stringify({
          playedTime: nowDate,
          isPlayed: isPlayed,
        }),
      );
    };

    if (suggestMovie === dataMovie) {
      const succesText = SuccessTextChecker(5 - step + 1);
      setSuccessText(succesText);
      setLocked(true);
      setTimeout(() => {
        setLocked(false);
        setFinished(true);
      }, 1500);
      saveSuccessData(true);
    } else {
      if (step > 1) {
        setStep((prev) => prev - 1);
        setSuggest("");
      } else {
        setFinished(true);
        saveSuccessData(true);
      }
    }
  };

  const goToMovie = async () => {
    sendItemClick("Hemen Keşfet");
    await new Promise(r => setTimeout(r, 500))
    window.location.href = data.link;
  };

  return (
    <div className="px-3 mt-6 w-full font-poppins">
      {!!successText && <SuccessCard text={successText} />}
      <div className="bg-game-card-bg px-4 py-[18px] rounded-xl flex flex-col gap-y-4">
        <div className="flex items-center justify-between">
          <h4 className="text-middle-gray text-sm font-medium">
            Kullanabileceğin Hakların
          </h4>
          <Steps step={step} />
        </div>
        <div className="flex flex-col gap-y-[14px]">
          <div className="relative">
            <img
              src={data?.images[5 - step]}
              alt="movie"
              className="rounded-lg w-full h-[180px] object-cover"
            />
            <div className="absolute left-[-16px] right-[-28px] px-3 bottom-1 overflow-x-auto scroll-container flex items-center gap-x-1">
              {filteredRecommendations?.map((rec) => (
                <ReccommendedMovieCard
                  key={rec}
                  movie={rec}
                  selectMovie={() => {
                    clearFilterRecommendations();
                    setMovieSelected(true);
                    setSuggest(rec);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <label
          htmlFor="movie-search"
          className="bg-movie-search-bg rounded-lg w-full py-2 pl-[18px] pr-2 flex items-center justify-between gap-x-4"
        >
          <input
            value={suggest}
            onChange={(e) => {
              setSuggest(e.target.value);
              setMovieSelected(false);
            }}
            type="text"
            placeholder="Programın adını yazın..."
            id="movie-search"
            className="outline-none border-0 w-full bg-transparent text-lg"
          />
          <button
            onClick={handleStep}
            className="bg-hero-pattern-red rounded-md w-11 h-11 flex items-center justify-center"
          >
            <img src="/assets/images/right-arrow.svg" alt="right-arrow" />
          </button>
        </label>
      </div>
      {finished && (
        <div
          className="fixed inset-0 z-[70]"
          style={{
            backgroundImage: `url(${data?.endImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "darken",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <div
            style={{
              backdropFilter: "blur(30px)",
              WebkitBackdropFilter: "blur(30px)", // Safari
            }}
            className="w-full h-screen backdrop-blur-3xl flex flex-col items-center justify-between pt-[72px] px-[26px] pb-[84px]"
          >
            <img src={logo} alt="channel" className="scale-125" />
            <img src={data?.endImage} alt="movie" className="max-w-[300px]" />
            <button
              style={{ marginBottom: "10px" }}
              onClick={goToMovie}
              className="py-[16px] px-[52px] rounded-[72px] text-white bg-hero-pattern-red text-base font-semibold font-poppins"
            >
              Hemen Keşfet
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const ReccommendedMovieCard = ({
  movie,
  selectMovie,
}: {
  movie: string;
  selectMovie: () => void;
}) => {
  return (
    <button
      onClick={selectMovie}
      className="bg-recommend-bg font-inter px-5 py-2.5 rounded-[72px] text-black font-semibold text-xs whitespace-nowrap"
    >
      {movie}
    </button>
  );
};

export default GameCard;
