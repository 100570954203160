import { ChannelType } from "../types/schedule";

export const channelChecker = (channel: ChannelType) => {
  const logoObj = {
    TLC: "/assets/logo/tlc_logo.svg",
    Dmax: "/assets/logo/dmax_logo.svg",
  };

  return logoObj[channel];
};

export const howPlayChecker = (channel: ChannelType) => {
  const howPlayObj = {
    Dmax: "DMAX’in efsane programlarından birini konuk ettiğimiz Moviedle’da amacın çok basit: Ekrana gelen sahneye bakarak programın adını doğru tahmin etmek. Elbette bunu ne kadar az denemede yaparsan o kadar iyi.",
    TLC: "TLC’nin efsane programlarından birini konuk ettiğimiz Moviedle’da amacın çok basit: Ekrana gelen sahneye bakarak programın adını doğru tahmin etmek. Elbette bunu ne kadar az denemede yaparsan o kadar iyi.",
  };

  return howPlayObj[channel];
};

export const SuccessTextChecker = (step: number) => {
  const successTextObj: { [key: string]: string } = {
    "1": "Dahisin! 🧠",
    "2": "Parlak zeka! ✨",
    "3": "Etkileyici! 😎",
    "4": "Harika! 👏",
    "5": "Ehh... 😏",
  };

  return successTextObj[step.toString()];
};
