export const Steps = ({ step }: { step: number }) => {
  return (
    <div className="flex items-center gap-x-1.5">
      <StepCircle step={5} currentStep={step} />
      <StepCircle step={4} currentStep={step} />
      <StepCircle step={3} currentStep={step} />
      <StepCircle step={2} currentStep={step} />
      <StepCircle step={1} currentStep={step} />
    </div>
  );
};

export const StepCircle = ({
  step,
  currentStep,
}: {
  step: number;
  currentStep: number;
}) => {
  return (
    <div
      className={`w-[26px] h-[26px] border border-hero-pattern-red rounded-full flex items-center justify-center ${
        step > currentStep ? "bg-black opacity-20" : "bg-transparent"
      }`}
    >
      <span style={{ fontSize: "12px" }} className="font-poppins text-white font-semibold">{step}</span>
    </div>
  );
};
