import { useCallback, useEffect, useState } from "react";

import { useOutsideClick } from "../hooks/useOutsideClick";
import { ChannelType } from "../types/schedule";
import { howPlayChecker } from "../utils/checker";

export const HopPlayModal = ({
  isOpen,
  setOpenModal,
  channel,
}: {
  isOpen: boolean;
  setOpenModal: (value: boolean) => void;
  channel: ChannelType;
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };
  const modalRef = useOutsideClick(handleClose);

  const [delayedOpen, setDelayedOpen] = useState<boolean>(false);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen) {
      setIsVisible(true);
      timer = setTimeout(() => {
        setDelayedOpen(true);
      }, 100);
    } else {
      setDelayedOpen(false);
      setIsVisible(false);
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  const text = useCallback(() => {
    return howPlayChecker(channel);
  }, [channel]);

  return (
    <div
      ref={modalRef}
      className={`fixed transition-all duration-700 ease-in-out overflow-hidden  ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } ${
        delayedOpen ? "max-h-[800px]" : "max-h-0"
      } z-[60] w-full bottom-0 pt-4 flex flex-col items-center gap-y-[30px] pb-12 px-7 right-0 bg-modal-bg rounded-t-[40px] `}
    >
      <div>
        <div className="bg-dragable-line rounded-[10px] h-1 w-12"></div>
      </div>
      <div className="px-2.5 flex flex-col gap-y-3 w-full font-poppins">
        <h1 className="text-hero-pattern-red text-2xl font-bold">
          NASIL OYNANIR?
        </h1>
        <div className="text-white text-base leading-[160%]">
          <p>{text()}</p>
          <ul className="mt-4">
            <li>
              1. Önce karşına gelen ilk kareyi incele ve programa dair en iyi
              tahminini yap.
            </li>
            <li className="mt-4">
              2. Tahminin yanlış çıkarsa sakın üzülme, yeni bir sahne açılacak
              ve tekrar tahminde bulunabileceksin.
            </li>
            <li className="mt-4">
              3. Ancak sadece 5 tahmin hakkın var, bu yüzden dikkatli ol ve
              sahnedeki tüm ipuçlarını yakalamaya çalış!
            </li>
          </ul>
          <p className="mt-4">İyi oyunlar!</p>
        </div>
      </div>
    </div>
  );
};
