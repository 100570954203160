import React from "react";

export const Title = () => {
  return (
    <div className="mt-6 px-7 flex flex-col gap-y-3 font-poppins">
      <h1 className="text-hero-pattern-red text-4xl font-bold uppercase">
        ekran tutkunları yarışıyor.
      </h1>
      <p className="text-base text-white">
        Aşağıdaki kare hangi programdan tahmin et
      </p>
    </div>
  );
};
