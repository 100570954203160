import { useEffect, useState } from "react";

import { ChannelType } from "../types/schedule";
import { channelChecker } from "../utils/checker";

export const Header = ({
  channel,
  handleOpenModal,
}: {
  channel: ChannelType;
  handleOpenModal: (value: boolean) => void;
}) => {
  const [logo, setLogo] = useState<string>("");

  useEffect(() => {
    const logo = channelChecker(channel);
    setLogo(logo);
  }, [channel]);
  return (
    <div className="px-7 w-full flex items-center justify-between">
      <div className="flex items-center gap-x-2 py-1.5">
        <img src={logo} alt="channel" className="h-5" />
        <div className="w-[1px] h-[14px] bg-white"></div>
        <p className="text-white font-medium text-xs">SPONSORLUĞUNDA</p>
      </div>
      <button
        onClick={() => {
          handleOpenModal(true);
        }}
      >
        <img src="/assets/images/info.svg" alt="info" />
      </button>
    </div>
  );
};
