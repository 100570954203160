import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ScreenProtect } from "./components/ScreenProtect";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ScreenProtect>
    <App />
  </ScreenProtect>
);
