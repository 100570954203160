import { useEffect, useState } from "react";

import { Header } from "./components/Header";
import { SelectedType } from "./types/schedule";
import { Overlay } from "./components/Overlay";
import { HopPlayModal } from "./components/HopPlayModal";
import { Title } from "./components/Title";
import GameCard from "./components/GameCard";

function App() {
  const [scheduleData, setScheduleData] = useState<SelectedType[]>([]);

  const [selected, setSelected] = useState<SelectedType | null>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [locked, setLocked] = useState<boolean>(false);

  useEffect(() => {
    const loadSchedule = async () => {
      const response = await fetch(`assets/schedule/schedule.json`);
      const jsonData = await response.json();
      setScheduleData(jsonData);
    };

    loadSchedule();
  }, []);

  const checkCurrentSchedule = () => {
    const now = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Istanbul",
    });
    const nowDate = new Date(now);

    const currentData = scheduleData.find((item: any) => {
      const start = new Date(item.startTime);
      const end = new Date(item.endTime);
      return (
        start.getTime() <= nowDate.getTime() &&
        nowDate.getTime() <= end.getTime()
      );
    });

    setSelected(currentData ? currentData : null);
  };

  useEffect(() => {
    if (scheduleData.length === 0) return;
    checkCurrentSchedule();

    const intervalId = setInterval(() => {
      checkCurrentSchedule();
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  if (!selected) {
    return <div className="bg-black h-screen w-full"></div>;
  }

  return (
    <>
      <div
        className={`bg-hero-pattern h-screen w-full pt-10 ${
          locked ? "pointer-events-none" : ""
        }`}
      >
        <Header channel={selected?.channel!} handleOpenModal={setOpenModal} />
        <Title />
        <GameCard data={selected!} setLocked={setLocked} />
      </div>
      <Overlay isOpen={openModal} />
      <HopPlayModal
        isOpen={openModal}
        setOpenModal={setOpenModal}
        channel={selected?.channel!}
      />
    </>
  );
}

export default App;
